import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import TourList from "~/components/tour-list";
import { Link } from "gatsby";

const SearchPage = ({
	data,
	// data: {
	// 	allStrapiTour: { edges },
	// },
}) => {
	//const  flatTours = edges.map(({ node }) => node);

	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);

	//const regions = data.allStrapiRegion.edges;
	//const flatRegions = regions.map(({ node }) => node);

	const asia = [
		{
			name: "Albania",
			to: "/destinations/albania-bike-tours/",
		},
		{
			name: "Austria",
			to: "/destinations/austria-bike-tours/",
		},
		{
			name: "Bhutan",
			to: "/destinations/bhutan-cycling-tours/",
		},
		{
			name: "Cambodia",
			to: "/destinations/cambodia-bike-tours/",
		},
		{
			name: "Chile",
			to: "/destinations/chile-bike-tours/",
		},
		{
			name: "Croatia",
			to: "/destinations/croatia-bike-tours/",
		},
		{
			name: "Czech Republic",
			to: "/destinations/czech-republic-bike-tours/",
		},
		{
			name: "France",
			to: "/destinations/france-bike-tours/",
		},
		{
			name: "Greece",
			to: "/destinations/greece-bike-tours/",
		},
		{
			name: "Hungary",
			to: "/destinations/hungary-bike-tours/",
		},
		{
			name: "India",
			to: "/destinations/india-bicycle-tours/",
		},
		{
			name: "Italy",
			to: "/destinations/italy-bike-tours/",
		},
		{
			name: "Jordan",
			to: "/destinations/jordan-bike-tours/",
		},
		{
			name: "Japan",
			to: "/destinations/japan-bike-tours/",
		},
		{
			name: "Laos",
			to: "/destinations/laos-bike-tours/",
		},
		{
			name: "Mongolia",
			to: "/destinations/mongolia-bike-tours/",
		},
		{
			name: "Montenegro",
			to: "/destinations/montenegro-bike-tours/",
		},
		{
			name: "Morocco",
			to: "/destinations/morocco-bike-tours/",
		},
		{
			name: "Nepal",
			to: "/destinations/nepal-bike-tours/",
		},
		{
			name: "Norway",
			to: "/destinations/norway-bike-tours/",
		},
		{
			name: "Philippines",
			to: "/destinations/philippines-bike-tours/",
		},
		{
			name: "Poland",
			to: "/destinations/poland-bike-tours/",
		},
		{
			name: "Portugal",
			to: "/destinations/portugal-bike-tours/",
		},
		{
			name: "Romania",
			to: "/destinations/romania-bike-tours/",
		},
		{
			name: "Slovakia",
			to: "/destinations/slovakia-bike-tours/",
		},
		{
			name: "Slovenia",
			to: "/destinations/slovenia-bike-tours/",
		},
		{
			name: "Sri Lanka",
			to: "/destinations/sri-lanka-cycling-tours/",
		},
		{
			name: "Spain",
			to: "/destinations/spain-bike-tours/",
		},
		{
			name: "Taiwan",
			to: "/destinations/taiwan-bike-tours/",
		},
		{
			name: "Thailand",
			to: "/destinations/thailand-bike-tours/",
		},
		{
			name: "Vietnam",
			to: "/destinations/vietnam-bike-tours/",
		},
	];

	//const seo = { title: "Tours" }

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title="Find Your Dream Bike Tour"
				description="Find you dream bicycle tour in India and Asia."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/tours",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/tours",
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/tours",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/tours",
				// 	},
				// ]}
			/>
			<PageHeading>Find Your Dream Bike Tour</PageHeading>
			<p className="mb-10 md:max-w-md lg:max-w-2xl">
				Choose from 100+ thoughtfully crafted bike tours, designed for those who
				love to stay active and curious while traveling.
			</p>
			{/* Region List Start */}
			<div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize pt-8 pb-16">
				{asia.map((reg) => {
					return (
						<Link key={reg.name} to={`${reg.to}`}>
							<div className="">
								<span className="border-2 font-medium border-primary p-4 md:p-6 rounded-lg">
									{reg.name}
								</span>
							</div>
						</Link>
					);
				})}
			</div>
			{/* Region List End */}
			{/* Region List Start */}
			{/* <div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize py-8 mb-10">
				{flatRegions &&
					flatRegions.map((reg) => {
						return (
							<div key={reg.id}>
								<Link
									to={`/destinations/${reg.slug}/?utm_source=tourlist&utm_medium=destination-link&utm_campaign=${reg.name}`}
									key={reg.id}
								>
									<div className="">
										<span className="border border-primary p-4 md:p-6 rounded-lg">
											{reg.name}
										</span>
									</div>
								</Link>
							</div>
						);
					})}
			</div> */}
			{/* Region List End */}
			<div className="mb-20">
				<TourList tours={flatTours} />
			</div>
		</Layout>
	);
};

// queries

// allStrapiRegion(sort: { fields: name, order: DESC }) {
// 	edges {
// 		node {
// 			id
// 			name
// 			slug
// 		}
// 	}
// }

// slider {
// 	id
// 	url
// 	name
// 	alternativeText
// 	localFile {
// 		childImageSharp {
// 			gatsbyImageData(aspectRatio: 1.3)
// 		}
// 	}
// }

export const searchPageQuery = graphql`
	query TourSearchQuery {
		allStrapiTour(sort: { fields: price, order: DESC }) {
			edges {
				node {
					title
					price
					duration
					slug
					popular
					new
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}

					quickfacts {
						lodging
					}

					activity {
						name
					}
					regions {
						name
					}
				}
			}
		}
	}
`;

export default SearchPage;
